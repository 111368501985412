import React, {useEffect, useRef} from 'react';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import 'foundation-sites';
import PropTypes from 'prop-types';
import {PrimaryCallout} from '../utilities/Callout';

const BulkActions = ({selectedParticipants}) => {
    const userIds = selectedParticipants.map(participant => participant.id);
    const foundationRef = useRef(null);
    const authToken = document.querySelector('meta[name=csrf-token]')?.content;
    useEffect(() => {
        $(foundationRef.current).foundation();
    }, [foundationRef]);

    const handleUpdateBulk = e => {
        const form = e.target;
        const settingsParams = {
            timeline_enabled: form.timelineEnabled.value, // eslint-disable-line camelcase
            video_length: form.videoLength.value, // eslint-disable-line camelcase
            daily_create_goal: form.dailyCreateGoal.value, // eslint-disable-line camelcase
            daily_replay_goal: form.dailyReplayGoal.value, // eslint-disable-line camelcase
            short_switch: form.shortSwitch.value, // eslint-disable-line camelcase
            replay_cap: form.replayCap.value // eslint-disable-line camelcase
        };

        const data = {
            user_ids: userIds, setting_attributes: settingsParams // eslint-disable-line camelcase
        };

        $.ajax({
            url: '/admin/users/update_bulk',
            type: 'PATCH',
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
            },
            data,
            success: () => {
                form.reset();
                notify.show('Users settings have been updated.', 'success');
                $('#settings-modal').foundation('close');
            },
            error: error => {
                notify.show(error.responseText, 'error');
            }
        });
        e.preventDefault();
    };

    const handleDestroyBulk = () => {
        const data = {user_ids: userIds}; // eslint-disable-line camelcase
        $.ajax({
            url: '/admin/users/destroy_bulk',
            type: 'DELETE',
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
            },
            data,
            error: error => {
                notify.show(error.responseText, 'error');
            }
        });
    };

    return (
        <div aria-disabled={userIds.length === 0} ref={foundationRef}>
            <ul className='dropdown menu' data-click-open='true' data-close-on-click-inside='true'
                data-disable-hover='true' data-dropdown-menu='true'>
                <li>
                    <a>Bulk Actions</a>
                    <ul className='vertical menu'>
                        <li>
                            <form action='/admin/users/export_to_csv' method='get'>
                                <input defaultValue={authToken} name='authenticity_token' type='hidden'/>
                                <input defaultValue={userIds} name='user_ids' type='hidden'/>
                                <button className='width-100 text-left' type='submit'>
                                    <a><i className='fa fa-download'/> Export to CSV</a>
                                </button>
                            </form>
                        </li>
                        <li>
                            <a data-open='settings-modal'><i className='fa fa-edit'/> Edit</a>
                        </li>
                        <li>
                            <a data-confirm='Are you sure?'
                               onClick={handleDestroyBulk}
                            >
                                <i className='fa fa-trash'/> Delete
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>

            <div className='reveal' data-close-on-esc='true' data-reveal='true' id='settings-modal'>
                <h2>Edit Participants ({selectedParticipants.length})</h2>
                <PrimaryCallout text='Settings that are left blank will not be modified.'/>
                <form onSubmit={e => handleUpdateBulk(e)}>

                    <div className='grid-x grid-margin-x small-up-1 medium-up-2'>
                        <div className='cell'>
                            <label>
                                Timeline Enabled:
                                <select id='timelineEnabled' name='timeline-enabled'>
                                    <option/>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                            </label>
                        </div>
                        <div className='cell'>
                            <label>
                                Video Length:
                                <select id='videoLength' name='video-length'>
                                    <option/>
                                    {[6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39, 42, 45, 48, 51, 54, 57, 60].map(length =>
                                        <option key={length} value={length}>{length}</option>
                                    )}
                                </select>
                            </label>
                        </div>
                    </div>

                    <div className='grid-x grid-margin-x small-up-1 medium-up-2'>
                        <div className='cell'>
                            <label>
                                Daily Create Goal:
                                <select id='dailyCreateGoal' name='daily-create-goal'>
                                    <option/>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(length =>
                                        <option key={length} value={length}>{length}</option>
                                    )}
                                </select>
                            </label>
                        </div>
                        <div className='cell'>
                            <label>
                                Daily Replay Goal:
                                <select id='dailyReplayGoal' name='daily-replay-goal'>
                                    <option/>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(length =>
                                        <option key={length} value={length}>{length}</option>
                                    )}
                                </select>
                            </label>
                        </div>
                    </div>

                    <div className='grid-x grid-margin-x small-up-1 medium-up-2'>
                        <div className='cell'>
                            <label>
                                Short Switch:
                                <select id='shortSwitch' name='short-switch'>
                                    <option/>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map(length =>
                                        <option key={length} value={length}>{length}</option>
                                    )}
                                </select>
                            </label>
                        </div>
                        <div className='cell'>
                            <label>
                                Replay Cap (set to 0 to disable):
                                <select id='replayCap' name='replay-cap'>
                                    <option/>
                                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50].map(length =>
                                        <option key={length} value={length}>{length}</option>
                                    )}
                                </select>
                            </label>
                        </div>
                    </div>

                    <input
                        className='margin-0 button'
                        type='submit'
                        value='Update Settings'
                    />
                </form>
                <button
                    aria-label='Close modal'
                    className='close-button primary-button'
                    data-close=''
                    id='Close-modal' type='button'>
                    <span aria-hidden='true'>&times;</span>
                </button>
            </div>
        </div>
    );
};

BulkActions.propTypes = {
    selectedParticipants: PropTypes.array.isRequired
};

export default BulkActions;