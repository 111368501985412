import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const LabsTableHeader = () =>
    <div className='curved primary thead'>
        <div className='grid-x grid-margin-x align-middle'>
            <div className='auto cell'>
                <small>Name</small>
            </div>
            <div className='auto cell'>
                <small>Researchers</small>
            </div>
            <div className='auto cell'>
                <small>Create Date</small>
            </div>
            <div className='auto cell'/>
        </div>
    </div>;

const LabsTableRow = ({lab}) =>
    <div
        className='tr'
    >
        <div className='grid-x grid-margin-x align-middle align-justify'>
            <div className='small-6 medium-auto cell text-truncate tight'>
                <div className='text-truncate'>{lab.name}</div>
            </div>
            <div className='small-6 medium-auto cell text-truncate tight'>
                <div className='text-truncate'>{lab.researchers}</div>
            </div>
            <div className='small-6 medium-auto cell'>
                <div className='text-truncate'>{moment(lab.createdAt).format('ll')}</div>
            </div>
            <div className='small-12 medium-auto cell text-right'>
                <a
                    className='small clear primary button'
                    href={`/admin/labs/${lab.id}`}
                >
                    <i className='fa fa-search'/> View
                </a>

                <a className='small clear alert button'
                    data-confirm='Are you sure?'
                    data-method='delete'
                    href={`/admin/labs/${lab.id}`}
                    rel='nofollow'
                >
                    <i className='fa fa-trash'/> Delete
                </a>
            </div>
        </div>
    </div>;

LabsTableRow.propTypes = {
    lab: PropTypes.shape({
        id: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        researchers: PropTypes.number.isRequired
    })
};

const LabsTable = ({labs}) =>
    <div>
        {
            labs.length > 0 &&
            <div className='curved table'>
                {labs.length > 0 && <LabsTableHeader/>}
                {labs.map(lab =>
                    <LabsTableRow
                        key={lab.id}
                        lab={lab}
                    />)
                }
            </div>
        }
    </div>;

LabsTable.propTypes = {
    labs: PropTypes.array.isRequired
};

export default LabsTable;