import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const StudiesTableHeader = () =>
    <div className='curved primary thead'>
        <div className='grid-x grid-margin-x align-middle'>
            <div className='auto cell'>
                <small>Name</small>
            </div>
            <div className='auto cell'>
                <small>Lab</small>
            </div>
            <div className='auto cell'>
                <small>Create Date</small>
            </div>
            <div className='auto cell'/>
        </div>
    </div>;

const StudiesTableRow = ({study}) =>
    <div
        className='tr'
    >
        <div className='grid-x grid-margin-x align-middle align-justify'>
            <div className='small-6 medium-auto cell text-truncate tight'>
                <div className='text-truncate'>{study.name}</div>
            </div>
            <div className='small-6 medium-auto cell text-truncate tight'>
                <div className='text-truncate'>{study.labName}</div>
            </div>
            <div className='small-6 medium-auto cell'>
                <div className='text-truncate'>{moment(study.createdAt).format('ll')}</div>
            </div>
            <div className='small-12 medium-auto cell text-right'>
                <a
                    className='small clear primary button'
                    href={`/admin/studies/${study.id}`}
                >
                    <i className='fa fa-search'/> View
                </a>
                <a className='small clear alert button'
                    data-confirm='Are you sure?'
                    data-method='delete'
                    href={`/admin/studies/${study.id}`}
                    rel='nofollow'
                >
                    <i className='fa fa-trash'/> Delete
                </a>
            </div>
        </div>
    </div>;

StudiesTableRow.propTypes = {
    study: PropTypes.shape({
        id: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        labName: PropTypes.string
    })
};

const StudiesTable = ({studies}) =>
    <div>
        {
            studies.length > 0 &&
            <div className='curved table'>
                {studies.length > 0 && <StudiesTableHeader/>}
                {studies.map(study =>
                    <StudiesTableRow
                        key={study.id}
                        study={study}
                    />)
                }
            </div>
        }
    </div>;
StudiesTable.propTypes = {
    studies: PropTypes.array.isRequired
};

export default StudiesTable;