import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const ResearchersTableHeader = () =>
    <div className='curved primary thead'>
        <div className='grid-x grid-margin-x align-middle'>
            <div className='auto cell'>
                <small>Full Name</small>
            </div>
            <div className='auto cell'>
                <small>Email</small>
            </div>
            <div className='auto cell'>
                <small>Joined Date</small>
            </div>
            <div className='auto cell'>
                <small>Last Sign In</small>
            </div>
            <div className='auto cell'>
                <small>Role</small>
            </div>
            <div className='auto cell'/>
        </div>
    </div>;
const ResearchersTableRow = ({researcher}) =>
    <div
        className='tr'
    >
        <div className='grid-x grid-margin-x align-middle align-justify'>
            <div className='small-6 medium-auto cell text-truncate tight'>
                <div className='text-truncate'>
                    {/* eslint-disable-next-line react/no-danger */}
                    {researcher.fullName} <span dangerouslySetInnerHTML={{__html: researcher.label}}/>
                </div>
            </div>
            <div className='small-6 medium-auto cell text-truncate tight'>
                <div className='text-truncate'>{researcher.email}</div>
            </div>
            <div className='small-6 medium-auto cell'>
                <div className='text-truncate'>{moment(researcher.createdAt).format('ll')}</div>
            </div>
            <div className='small-6 medium-auto cell'>
                <div className='text-truncate'>
                    {researcher.lastSignInAt &&
                        moment(researcher.lastSignInAt).format('ll')
                    }
                </div>
            </div>
            <div className='small-6 medium-auto cell text-truncate tight'>
                <div className='text-truncate'>{researcher.role}</div>
            </div>
            <div className='small-12 medium-auto cell text-right'>
                <a
                    className='small clear primary button'
                    href={`/admin/researchers/${researcher.id}`}
                >
                    <i className='fa fa-search'/> View
                </a>
            </div>
        </div>
    </div>;

ResearchersTableRow.propTypes = {
    researcher: PropTypes.shape({
        id: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
        lastSignInAt: PropTypes.string,
        fullName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired
    })
};

const ResearchersTable = ({researchers}) =>
    <div className='curved table'>
        {researchers.length > 0 && <ResearchersTableHeader/>}
        {researchers.map(researcher =>
            <ResearchersTableRow
                key={researcher.id}
                researcher={researcher}
            />)}
    </div>;

ResearchersTable.propTypes = {
    researchers: PropTypes.array.isRequired
};

export default ResearchersTable;