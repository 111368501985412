import React, {useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import BulkActions from './BulkActions.jsx.erb';

const UsersTableHeader = ({isSelected, handleSelectToggleAll, handleSort, sortBy, sortDirection}) => {
    const tableHeaders = ['Participant ID', 'Create Date', 'Lab', 'Study', 'Last Activity'];
    const columnNames = ['participant_id', 'created_at', 'labs.name', 'studies.name', 'updated_at'];
    const sortDirections = ['none', 'asc', 'desc'];
    const sortIcons = ['fa fa-sort', 'fa fa-sort-down', 'fa fa-sort-up'];

    const getSortIcon = index => {
        if (sortBy && sortDirection && columnNames.indexOf(sortBy) === index) {
            return sortIcons[sortDirections.indexOf(sortDirection)];
        }

        return sortIcons[0];
    };

    const changeSort = newSortBy => {
        if (sortBy === newSortBy) {
            handleSort(newSortBy, sortDirections[(sortDirections.indexOf(sortDirection) + 1) % sortDirections.length]);
        }
        else {
            handleSort(newSortBy, 'asc');
        }
    };

    return (
        <div className='curved primary thead'>
            <div className='grid-x grid-margin-x align-middle'>
                <div className='shrink cell'>
                    <input checked={isSelected}
                        className='margin-0'
                        name='allSelect'
                        onChange={event => handleSelectToggleAll(event)}
                        type='checkbox'
                    />
                </div>
                {
                    tableHeaders.map((header, index) =>
                        <div
                            className='auto cell'
                            key={header}
                            onClick={() => {
                                changeSort(columnNames[index]);
                            }}
                            style={{cursor: 'pointer'}}>
                            <small>{header}
                                <i className={`${getSortIcon(index)} fa-fw`}/>
                            </small>
                        </div>)
                }
                <div className='auto cell'/>
            </div>
        </div>
    );
};

UsersTableHeader.defaultProps = {
    sortDirection: 'none'
};

UsersTableHeader.propTypes = {
    handleSelectToggleAll: PropTypes.func.isRequired,
    handleSort: PropTypes.func.isRequired,
    isSelected: PropTypes.bool.isRequired,
    sortBy: PropTypes.string,
    sortDirection: PropTypes.oneOf(['none', 'asc', 'desc'])
};

const UsersTableRow = ({user, isSelected, handleSelectToggle}) =>
    <div
        className='tr'
    >
        <div className='grid-x grid-margin-x align-middle align-justify'>
            <div className='shrink cell'>
                <input
                    checked={isSelected}
                    className='margin-0'
                    name={user.participantId}
                    onChange={event => handleSelectToggle(event, user)}
                    type='checkbox'/>
            </div>
            <div className='small-6 medium-auto cell text-truncate tight'>
                <div className='text-truncate'>{user.participantId}</div>
                <small className='text-truncate'>{user.uid}</small>
            </div>
            <div className='small-6 medium-auto cell'>
                <div className='text-truncate'>{moment(user.createdAt).format('ll')}</div>
            </div>
            <div className='small-6 medium-auto cell'>
                <div className='text-truncate'>{user.labName}</div>
            </div>
            <div className='small-6 medium-auto cell'>
                <div className='text-truncate'>{user.studyName}</div>
            </div>
            <div className='small-6 medium-auto cell'>
                <div className='text-truncate'>{moment(user.updatedAt).format('ll')}</div>
            </div>
            <div className='small-12 medium-auto cell text-right'>
                <a
                    className='small clear primary button'
                    href={`/admin/users/${user.id}`}
                >
                    <i className='fa fa-search'/> View
                </a>
                <a className='small clear alert button'
                    data-confirm='Are you sure?'
                    data-method='delete'
                    href={`/admin/users/${user.id}`}
                    rel='nofollow'
                >
                    <i className='fa fa-trash'/> Delete
                </a>
            </div>
        </div>
    </div>;

UsersTableRow.propTypes = {
    handleSelectToggle: PropTypes.func.isRequired,
    isSelected: PropTypes.bool.isRequired,
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
        labName: PropTypes.string,
        studyName: PropTypes.string,
        updatedAt: PropTypes.string.isRequired,
        participantId: PropTypes.string.isRequired,
        uid: PropTypes.string.isRequired
    })
};

const UsersTable = ({users, handleSort, sortBy, sortDirection}) => {
    const [selectedParticipants, setSelectedParticipants] = useState([]);

    const handleSelectToggle = (e, user) => {
        const {checked} = e.target;

        if (checked) {
            setSelectedParticipants([...selectedParticipants, user]);
        }
        else {
            const tempParticipant = selectedParticipants.filter(item => item.id !== user.id);
            setSelectedParticipants(tempParticipant);
        }
    };

    const handleSelectToggleAll = e => {
        const {name, checked} = e.target;

        if (name === 'allSelect' && checked) {
            setSelectedParticipants(users);
        }
        else {
            setSelectedParticipants([]);
        }
    };

    return (
        <div>
            {users.length > 0 && <>
                <BulkActions selectedParticipants={selectedParticipants}/>
                <div className='curved table'>
                    {users.length > 0 && <UsersTableHeader
                        handleSelectToggleAll={handleSelectToggleAll}
                        handleSort={handleSort}
                        isSelected={selectedParticipants.length === users.length}
                        sortBy={sortBy} sortDirection={sortDirection}
                        users={users}/>}
                    {users.map(user =>
                        <UsersTableRow
                            handleSelectToggle={handleSelectToggle}
                            isSelected={selectedParticipants.some(item => item?.id === user.id)}
                            key={user.id}
                            user={user}
                        />)}
                </div>
            </>}
        </div>
    );
};

UsersTable.propTypes = {
    handleSort: PropTypes.func.isRequired,
    sortBy: PropTypes.string,
    sortDirection: PropTypes.oneOf(['none', 'asc', 'desc']),
    users: PropTypes.array.isRequired
};

export default UsersTable;