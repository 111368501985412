import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import DialogCloseButton from './DialogCloseButton';
import DateField from './DateField';

export default class SearchDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            createdFrom: props.filters.createdFrom,
            createdTo: props.filters.createdTo,
            labIds: props.filters.labIds || [],
            lastSignInAtFrom: props.filters.lastSignInAtFrom,
            lastSignInAtTo: props.filters.lastSignInAtTo,
            studyIds: props.filters.studyIds || [],
            updatedFrom: props.filters.updatedFrom,
            updatedTo: props.filters.updatedTo
        };
    }

    handleClearFilters = () => {
        const filters = {
            createdFrom: null,
            createdTo: null,
            labIds: [],
            lastSignInAtFrom: null,
            lastSignInAtTo: null,
            studyIds: [],
            updatedFrom: null,
            updatedTo: null
        };
        this.setState({...filters}, () => this.props.callback({}));
    };

    handleLabIds = e => {
        const options = [];

        for (let i = 0; i < e.target.selectedOptions.length; i++) {
            options.push(e.target.selectedOptions[i].value);
        }

        this.setState({labIds: options});
    };

    handleStudyIds = e => {
        const options = [];

        for (let i = 0; i < e.target.selectedOptions.length; i++) {
            options.push(e.target.selectedOptions[i].value);
        }

        this.setState({studyIds: options});
    };

    handleSubmit = e => {
        let returnState = {
            createdFrom: this.state.createdFrom,
            createdTo: this.state.createdTo,
            labIds: this.state.labIds,
            studyIds: this.state.studyIds,
            updatedFrom: this.state.updatedFrom,
            updatedTo: this.state.updatedTo
        };

        if (this.props.displayFilters.lastSignInAt) {
            returnState = {...returnState,
                lastSignInAtFrom: this.state.lastSignInAtFrom,
                lastSignInAtTo: this.state.lastSignInAtTo};
        }

        this.props.callback(returnState);
        e.preventDefault();
    };

    render() {
        return (
            <div
                className='reveal'
                id='search-filters-dialog'
            >
                <p className='lead'>Enable Search Filters</p>
                <form
                    id='search-form'
                    onSubmit={this.handleSubmit}
                >
                    <label><b>Create Date Range</b></label>
                    <div className='grid-x grid-margin-x'>
                        <div className='auto cell'>
                            <DateField
                                name='createdFrom'
                                onChange={createdFrom =>
                                    this.setState({createdFrom: new Date(createdFrom).toISOString()})}
                                value={this.state.createdFrom}
                            />
                        </div>
                        <div className='shrink cell'>
                            <label className='middle lead'>to</label>
                        </div>
                        <div className='auto cell'>
                            <DateField
                                name='createdTo'
                                onChange={createdTo =>
                                    this.setState({createdTo: new Date(createdTo).toISOString()})}
                                value={this.state.createdTo}
                            />
                        </div>
                    </div>

                    {this.props.displayFilters.lastSignInAt &&
                        <>
                            <label><b>Last Sign In Date Range</b></label>
                            <div className='grid-x grid-margin-x'>
                                <div className='auto cell'>
                                    <DateField
                                        name='lastSignInAtFrom'
                                        onChange={lastSignInAtFrom =>
                                            this.setState({lastSignInAtFrom: new Date(lastSignInAtFrom).toISOString()})}
                                        value={this.state.lastSignInAtFrom}
                                    />
                                </div>
                                <div className='shrink cell'>
                                    <label className='middle lead'>to</label>
                                </div>
                                <div className='auto cell'>
                                    <DateField
                                        name='lastSignInAtTo'
                                        onChange={lastSignInAtTo =>
                                            this.setState({lastSignInAtTo: new Date(lastSignInAtTo).toISOString()})}
                                        value={this.state.lastSignInAtTo}
                                    />
                                </div>
                            </div>
                        </>
                    }

                    {this.props.usersPage &&
                        <>
                            <label><b>Last Active Date Range</b></label>
                            <div className='grid-x grid-margin-x'>
                                <div className='auto cell'>
                                    <DateField
                                        name='updatedFrom'
                                        onChange={updatedFrom =>
                                            this.setState({updatedFrom: new Date(updatedFrom).toISOString()})}
                                        value={this.state.updatedFrom}
                                    />
                                </div>
                                <div className='shrink cell'>
                                    <label className='middle lead'>to</label>
                                </div>
                                <div className='auto cell'>
                                    <DateField
                                        name='updatedTo'
                                        onChange={updatedTo =>
                                            this.setState({updatedTo: new Date(updatedTo).toISOString()})}
                                        value={this.state.updatedTo}
                                    />
                                </div>
                            </div>
                            <div className='grid-x grid-margin-x'>
                                <div className='auto cell'>
                                    <label><b>Lab (Select multiple)</b></label>
                                    <div className='grid-x grid-margin-x'>
                                        <div className='auto cell'>
                                            <select id='lab' multiple name='lab'
                                                onChange={this.handleLabIds} value={this.state.labIds}>
                                                {this.props.usersPage &&
                                                    this.props.labs.map(lab =>
                                                        <option key={lab.id} value={lab.id}>{lab.name}</option>)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='auto cell'>
                                    <label><b>Study (Select multiple)</b></label>
                                    <div className='grid-x grid-margin-x'>
                                        <div className='auto cell'>
                                            <select id='study' multiple name='study'
                                                onChange={this.handleStudyIds} value={this.state.studyIds}>
                                                {this.props.usersPage &&
                                                    this.props.studies.map(study =>
                                                        <option key={study.id} value={study.id}>{study.name}</option>)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='grid-x grid-margin-x align-justify'>
                                <div className='shrink cell'>
                                    <button
                                        className='margin-bottom-0 secondary button'
                                        data-close=''
                                        onClick={this.handleClearFilters}
                                        type='button'
                                    >
                                        <i className='fa fa-times fa-fw'/>Clear Filters
                                    </button>
                                </div>
                                <div className='auto cell'>
                                    <button
                                        className='expanded margin-bottom-0 primary button'
                                        data-close=''
                                        type='submit'
                                    >
                                        <i className='fa fa-filter fa-fw'/>Apply Filters
                                    </button>
                                </div>
                            </div>
                        </>
                    }
                </form>

                <DialogCloseButton/>
            </div>
        );
    }
}

SearchDialog.propTypes = {
    callback: PropTypes.func.isRequired,
    displayFilters: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    labs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
    })).isRequired,
    studies: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
    })).isRequired,
    usersPage: PropTypes.bool.isRequired

};