import React, {PureComponent} from 'react';
import LabsTable from './LabsTable';
import Rails from '@rails/ujs';
import {notify} from 'react-notify-toast';
import Pagination from '../Pagination';
import SearchDialog from '../SearchDialog';
import Foundation from 'foundation-sites';
import $ from 'jquery';
import PropTypes from 'prop-types';
import {PrimaryCallout} from '../utilities/Callout';
import Mark from 'mark.js/dist/mark.es6';

export default class LabsIndex extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            labs: [],
            query: props.filters.query || '',
            loading: props.totalCount > 0,
            page: Math.min(Number(props.filters.page || 1),
                Math.ceil(props.totalCount / Number(props.filters.records || props.recordsPerPage))),
            recordsPerPage: Number(props.filters.records || props.recordsPerPage),
            totalCount: 0,
            filters: props.filters
        };
        this.dialog = null;
    }

    componentDidMount() {
        if (this.props.totalCount > 0) {
            this.findLabs(this.state.page);
        }

        this.instance = new Mark(document.querySelector('#labs-list'));
    }

    componentDidUpdate() {
        this.instance.unmark();
        this.instance.mark(this.state.query);
    }

    handleOpenSearchDialog = () => {
        if (!this.dialog) {
            this.dialog = new Foundation.Reveal($('#search-filters-dialog'));
        }

        this.dialog.open();
    };

    findLabs = (page = 1) => {
        const queryParams = `query=${this.state.query}`;
        const paginationParams = `page=${page}&records=${this.state.recordsPerPage}`;
        const filtersParams = $.param(this.state.filters);
        const timer = setTimeout(() => this.setState({loading: true}), 250);
        Rails.ajax({
            url: `/admin/labs/search.json?${filtersParams}&${paginationParams}&${queryParams}`,
            type: 'get',
            success: data => {
                this.setState({labs: data.array, totalCount: data.totalCount, page, loading: false});
            },
            error: error => {
                this.setState({labs: [], page, loading: false});
                notify.show(error, 'error');
            },
            complete: () => {
                clearTimeout(timer);
            }
        });
    };

    filtersEnabledCount = () => {
        const {filters} = this.state;

        let count = 0;

        if (filters.createdFrom && filters.createdFrom.toString().length ||
            filters.createdTo && filters.createdTo.toString().length) {
            count++;
        }

        return count;
    };

    applySearchFilters = filters => {
        this.setState({filters}, this.findLabs);
    };

    handleSearchLabs = e => {
        const query = e.target.value.trim();
        this.setState({query}, this.findLabs);
    };

    handleChangeRecordsPerPage = e => {
        this.setState({recordsPerPage: Number(e.target.value)}, this.findLabs);
    };

    changePage = page => {
        this.findLabs(page);
    };

    renderNavigation = () =>
        <>
            <h1>Labs</h1>
            <div className='grid-x grid-margin-x align-justify'>
                <div className='auto cell'>
                    <div className='grid-x grid-margin-x align-middle'>
                        <div className='auto medium-shrink cell'>
                            <div className='input-group'>
                                <label
                                    className='input-group-label'
                                    htmlFor='text'
                                >
                                    <i className='fa fa-search'/>
                                </label>
                                <input
                                    className='input-group-field'
                                    defaultValue={this.props.filters.query}
                                    id='text'
                                    name='text'
                                    onChange={this.handleSearchLabs}
                                    placeholder='search'
                                    type='search'
                                />
                                <div
                                    className='input-group-label relative'
                                    onClick={this.handleOpenSearchDialog}
                                >
                                    <i className='fa fa-filter fa-fw'/><span className='show-for-medium'>Filter</span>
                                    {this.filtersEnabledCount() > 0 &&
                                    <b className='float primary badge'>
                                        {this.filtersEnabledCount()}
                                    </b>}
                                </div>
                            </div>
                        </div>
                        <div className='shrink cell'>
                            <div className='input-group'>
                                <label
                                    className='input-group-label show-for-large'
                                    htmlFor='records'
                                >
                                    # per page
                                </label>
                                <select
                                    className='input-group-field'
                                    defaultValue={this.state.recordsPerPage}
                                    id='records'
                                    onChange={this.handleChangeRecordsPerPage}
                                >
                                    <option>5</option>
                                    <option>10</option>
                                    <option>25</option>
                                    <option>50</option>
                                    <option>100</option>
                                    <option>250</option>
                                </select>
                            </div>
                        </div>
                        <div className='shrink cell show-for-large'>
                            <p>{this.renderRecordsShown()}</p>
                        </div>
                    </div>
                </div>
                <div className='shrink cell text-right'>
                    <a
                        className='button'
                        href='/admin/labs/new'
                    >
                        New Lab
                    </a>
                </div>
            </div>
        </>;

    renderRecordsShown = () => {
        const from = (this.state.page - 1) * this.state.recordsPerPage + 1;
        const to = Math.min(this.state.page * this.state.recordsPerPage, this.state.totalCount);

        if (this.state.loading) {
            return <span><i className='fa fa-spinner fa-pulse fa-fw'/>Loading data...</span>;
        }
        else if (this.state.totalCount === 0) {
            return null;
        }

        return <small>Records: {from}-{to} of {this.state.totalCount}</small>;
    };

    renderPagination = () =>
        <Pagination
            callback={this.changePage}
            page={this.state.page}
            recordsPerPage={this.state.recordsPerPage}
            totalCount={this.state.totalCount}
        />;

    renderLabs = () =>
        <div>
            {this.renderPagination()}
            <LabsTable labs={this.state.labs}/>
            {this.renderPagination()}
        </div>;

    render() {
        return (
            <div id='labs-list'>
                {this.renderNavigation()}
                {this.props.totalCount === 0 && <PrimaryCallout text='No labs have been created yet...'/>}
                {this.props.totalCount > 0 && this.state.totalCount === 0 && !this.state.loading &&
                <PrimaryCallout text='Your search did not return any results. Try broadening your search criteria.'/>}
                {this.renderLabs()}
                <SearchDialog
                    callback={this.applySearchFilters}
                    displayFilters={{lastSignInAt: false}}
                    filters={this.props.filters}
                />
            </div>
        );
    }
}

LabsIndex.propTypes = {
    filters: PropTypes.object.isRequired,
    recordsPerPage: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired
};